exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beans-index-js": () => import("./../../../src/pages/beans/index.js" /* webpackChunkName: "component---src-pages-beans-index-js" */),
  "component---src-pages-beans-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/beans/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-beans-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-blends-index-js": () => import("./../../../src/pages/blends/index.js" /* webpackChunkName: "component---src-pages-blends-index-js" */),
  "component---src-pages-blends-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/blends/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blends-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-roasts-index-js": () => import("./../../../src/pages/roasts/index.js" /* webpackChunkName: "component---src-pages-roasts-index-js" */),
  "component---src-pages-roasts-markdown-remark-frontmatter-batch-js": () => import("./../../../src/pages/roasts/{MarkdownRemark.frontmatter__batch}.js" /* webpackChunkName: "component---src-pages-roasts-markdown-remark-frontmatter-batch-js" */)
}

